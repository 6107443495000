import React, { useState } from "react";
import PropTypes from "prop-types";

import { Stack } from "@mui/material";
import axios from "axios";
import clsx from "clsx";
import { Formik, Form } from "formik";
import { ChromePicker } from "react-color";
import Dropzone from "react-dropzone";
import { useHistory } from "react-router-dom";
import urljoin from "url-join";

import { alertError } from "@shared/Alerts";
import SelectField from "@shared/forms/SelectField";
import {
  renderCheckField,
  renderCancelButton,
  renderDateField,
  renderSubmitButton,
  renderTextField
} from "@shared/FormUtils";
import { useEventMetadata } from "@shared/hooks/useEventMetadata";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";
import RadioField from "@shared/RadioField";
import RegexField from "@shared/RegexField";
import TimeZonePickerField from "@shared/TimeZonePickerField";

import MainBlankEvent from "./MainBlankEvent";

const MainEventForm = ({ event, rootUrl }) => {
  const history = useHistory();
  const eventMetadata = event && event.id ? useEventMetadata(event.id) : {}

  const [color, setColor] = useState(event.color);
  const [tile, setTile] = useState(null);
  const [tileDropped, setTileDropped] = useState(false);
  const [previewTileURL, setPreviewTileURL] = useState(event.tile_url);

  const dropzoneAccept = "image/png, image/jpg, image/jpeg, image/pjpeg";
  const formId = "sg-mgmt-event-form";

  const formInitialValues = () => {
    if (event && event.gid) {
      return {
        event: {
          name: event.name || "",
          archived: event.archived || false,
          event_type: event.event_type || "physical",
          location: event.location || "",
          date_begin: event.date_begin || null,
          date_end: event.date_end || null,
          time_zone: event.time_zone || "",
          slug: event.slug || "",
          growl_web_url: event.growl_web_url || "",
          growl_web_key: event.growl_web_key || "",
          site_url: event.site_url || "",
          postmark_mail_key: event.postmark_mail_key || "",
          metadata: initialMetadataValues()
        }
      };
    }
    return { event: MainBlankEvent };
  };

  const config = (() => {
    if (event && event.gid) {
      return {
        button: "Save Changes",
        cancelUrl: urljoin(rootUrl, event.slug),
        formUrl: urljoin(rootUrl, "/-/events/", event.slug),
        formMethod: "PATCH",
        title: "Edit Event"
      };
    }
    return {
      button: "Create Event",
      cancelUrl: urljoin(rootUrl, "/events"),
      formUrl: urljoin(rootUrl, "/-/events"),
      formMethod: "POST",
      title: "Create New Event"
    };
  })();

  const onColorChange = (newColor) => {
    setColor(newColor.hex);
  };

  const onDrop = (acceptedFiles) => {
    const reader = new FileReader();
    if (typeof window.FileReader !== "undefined") {
      reader.onload = (e) => {
        setTile(acceptedFiles[0]);
        setTileDropped(true);
        setPreviewTileURL(e.target.result);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    } else {
      setTile(acceptedFiles[0]);
      setTileDropped(true);
      setPreviewTileURL(null);
    }
  };

  const dropzoneFilename = () => {
    if (tileDropped) {
      return (
        <>
          File attached:
          <br />
          {tile.name}
        </>
      );
    }
    return <></>;
  };

  const renderDateBegin = (maxDate) => {
    const dateLimit = maxDate instanceof Date ? maxDate : new Date(`${maxDate}T00:00:00`);
    return (
      <div className="sg-mgmt-form-input-container sg-mgmt-form-input-container-date">
        {renderDateField("Start Date", "event[date_begin]", [], {
          fieldProperties: { maxDate: dateLimit }
        })}
      </div>
    );
  };

  const renderDateEnd = (minDate) => {
    const dateLimit = minDate instanceof Date ? minDate : new Date(`${minDate}T00:00:00`);
    return (
      <div className="sg-mgmt-form-input-container sg-mgmt-form-input-container-date">
        {renderDateField("End Date", "event[date_end]", [], {
          fieldProperties: { minDate: dateLimit }
        })}
      </div>
    );
  };

  const renderTimeZoneField = (label, field, formatClasses = []) => (
    <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
      <label>{label}</label>
      <TimeZonePickerField
        className="sg-mgmt-form-input"
        name={`event[${field}]`}
        fieldName={`event[${field}]`}
        autoComplete="off"
      />
    </div>
  );

  const renderEventTypeField = () => {
    const options = [
      { label: "Physical (in-person)", value: "physical" },
      { label: "Virtual (online)", value: "virtual" },
      { label: "Hybrid (in-person and online)", value: "hybrid" }
    ];
    return <RadioField fieldName="event[event_type]" options={options} integer={false} />;
  };

  const renderPhysicalFields = (eventType) => {
    if (eventType === "physical" || eventType === "hybrid") {
      return <div className="sg-mgmt-form-row">{renderTextField("Location - City, State", "event[location]")}</div>;
    }
    return <></>;
  };

  const renderGrowlWebUrlField = () => (
    <div className="sg-mgmt-form-row">{renderTextField("Growl web root URL", "event[growl_web_url]")}</div>
  );

  const renderGrowlWebKeyField = () => (
    <div className="sg-mgmt-form-row">{renderTextField("Growl web API key", "event[growl_web_key]")}</div>
  );

  const renderSlugField = () => (
    <div className={clsx("sg-mgmt-form-input-container", "sg-mgmt-form-input-slug")}>
      <label>URL name (letters, numbers, and hyphens only)</label>
      <div className="flex items-center">
        <div className="sg-mgmt-form-input-slug-label">company.silentgrowl.com/</div>
        <RegexField
          className="sg-mgmt-form-input sg-mgmt-form-input-slug"
          regex={RegExp(/^[0-9a-z-]*$/)}
          name="event[slug]"
          autoComplete="off"
        />
      </div>
    </div>
  );

  const renderColorPicker = () => (
    <ChromePicker className="sg-mgmt-color-picker" color={color} disableAlpha onChangeComplete={onColorChange} />
  );

  const renderTilePreview = (header = false) => {
    const previewClass = header ? "sg-mgmt-form-tile-preview-header" : "";
    if (tileDropped && previewTileURL) {
      return (
        <div className="sg-mgmt-form-tile-preview-container">
          <img className={clsx("sg-mgmt-form-tile-preview", previewClass)} src={previewTileURL} alt="Tile Preview" />
        </div>
      );
    }
    return <></>;
  };

  const renderTileUpload = () => {
    return (
      <Dropzone accept={dropzoneAccept} onDrop={onDrop} name="event[tile]">
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              {...getRootProps()}
              className={clsx("dropzone", "sg-mgmt-form-input-dropzone-tile", {
                "dropzone--isActive": isDragActive
              })}
            >
              <input {...getInputProps()} />
              <div className="sg-mgmt-form-input-dropzone-tile-prompt">
                Drag and Drop
                <br />
                or Click to Choose File
              </div>
              <div className="sg-mgmt-form-input-dropzone-tile-filename">{dropzoneFilename()}</div>
            </div>
          );
        }}
      </Dropzone>
    );
  };

  const renderButtons = (isSubmitting) => (
    <Stack direction="row" spacing={2}>
      {renderSubmitButton(config.button, isSubmitting)}
      {renderCancelButton("Cancel", () => {
        history.push(config.cancelUrl);
      })}
    </Stack>
  );

  const renderCustomTextField = (field, metaType = "custom") => {
    return renderTextField(field.label, `event[${metaType}][${field.slug}]`, []);
  };

  const renderCustomDateField = (field, metaType = "custom") => {
    return renderDateField(field.label, `event[${metaType}][${field.slug}]`, []);
  };

  const renderCustomDropdownField = (field, metaType = "custom", formatClasses = {}) => {
    const options = field.options.map((opt) => ({
      label: opt.label,
      value: opt.label
    }));

    return (
      <SelectField
        label={field.label}
        name={`event[${metaType}][${field.slug}]`}
        options={options}
        formatClasses={formatClasses}
        includeBlank={true}
      />
    );
  };

  const renderCustomCheckField = (field, metaType = "custom", formatClasses = {}) => {
    const options = field.options.map((opt) => ({
      id: opt.id,
      label: opt.label,
      slug: opt.slug
    }));
    return (
      <div key={field.gid} className={clsx("sg-mgmt-form-input-container", formatClasses)}>
        <label>{field.label}</label>
        {options.map((opt) =>
          renderCheckField(opt.label, `event[${metaType}][${field.slug}][options][${opt.slug}]`)
        )}
      </div>
    );
  };

  const renderMetadataField = (field) => {
    switch (field.field_format) {
      case "text":
        return renderCustomTextField(field, "metadata");
      case "date":
        return renderCustomDateField(field, "metadata");
      case "dropdown":
        return renderCustomDropdownField(field, "metadata");
      case "checkboxes":
        return renderCustomCheckField(field, "metadata");
      default:
        return <div key={field.gid}>not a text field</div>;
    }
  };

  const renderMetadataFields = () => {
    return eventMetadata.data.map((f) => (
      <div key={f.id} className="sg-mgmt-form-row">
        {renderMetadataField(f)}
      </div>
    ));
  };

  const initialMetadataValues = () => {
    const metadataValues = {};
    event.metadata.forEach((md) => {
      if (md.field_format == "checkboxes") {
        const val = { options: {} };
        md.value.split(",").forEach((opt) => {
          val["options"][opt] = true;
        });
        metadataValues[md.field_slug] = val;
      } else {
        metadataValues[md.field_slug] = md.value;
      }
    });
    return metadataValues;
  };

  const renderForm = () => {
    return (
      <div className="sg-mgmt-event-container">
        <Formik
          initialValues={formInitialValues()}
          onSubmit={(values, { setSubmitting }) => {
            const form = document.getElementById(formId);
            const formData = new FormData(form);
            formData.set("event[color]", color);

            if (document.body.dataset.environment !== "test") {
              const token = document.querySelector("[name=csrf-token]").content;
              axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
            }

            let abort = false;
            const slugRegex = new RegExp("^[0-9A-Za-z-]{3,32}$");

            if (tile) {
              formData.set("event[tile]", tile);
            }

            if (values.event.archived === false) {
              formData.set("event[archived]", false);
            }

            if (values && values.event.metadata) {
              const objectFieldsKeys = Object.keys(values.event.metadata).filter(
                (key) => typeof values.event.metadata[key] === "object"
              );
              objectFieldsKeys.forEach((key) => {
                const value = values.event.metadata[key];
                if (
                  value &&
                  value.options &&
                  Object.keys(value.options).filter((key) => value.options[key]).length == 0
                ) {
                  formData.set(`event[metadata][${key}]`, "");
                }
              });
            }

            if (values.event.slug === "") {
              alertError("You cannot leave URL name blank.");
              abort = true;
            }

            if (!slugRegex.test(values.event.slug)) {
              alertError(
                "URL name must have letters, numbers, and hyphens only, and must be between 3 and 32 characters."
              );
              abort = true;
            }

            if (abort) {
              setSubmitting(false);
              return;
            }

            axios({
              url: config.formUrl,
              method: config.formMethod,
              data: formData
            }).then((response) => {
              if (response.data.error === null) {
                window.location.replace(urljoin(rootUrl, response.data.event.path));
              } else {
                alertError(response.data.error);
                setSubmitting(false);
              }
            });
          }}
        >
          {({ isSubmitting, values }) => (
            <Form className="sg-mgmt-form" id={formId}>
              <div className="sg-mgmt-form-container">
                <div className="mb-4">{renderButtons(isSubmitting)}</div>
                <div className="sg-mgmt-form-section">
                  <div className="sg-mgmt-form-row">{renderTextField("Name of your event", "event[name]")}</div>
                  <div className="sg-mgmt-form-row">{renderCheckField("Archived", "event[archived]")}</div>
                </div>
                <div className="sg-mgmt-form-section">
                  <h2 className="mb-2">Event Type</h2>
                  {renderEventTypeField()}
                </div>
                {renderPhysicalFields(values.event.event_type)}
                <div className="sg-mgmt-form-section-divider mb-2 pt-2" />
                <div className="sg-mgmt-form-section">
                  <h2 className="mb-2">Date and Time</h2>
                  <div className="sg-mgmt-form-row">
                    {renderDateBegin(values.event.date_end)}
                    {renderDateEnd(values.event.date_begin)}
                    {renderTimeZoneField("Time Zone", "time_zone", "sg-mgmt-form-input-container-timezone")}
                  </div>
                </div>
                <div className="sg-mgmt-form-section-divider mb-2 pt-2" />
                <div className="sg-mgmt-form-section">
                  <h2 className="mb-2">Customize Event Management Page</h2>
                  <div className="sg-mgmt-form-row">{renderSlugField()}</div>
                  <div className="sg-mgmt-form-row">
                    <div className="sg-mgmt-form-input-container">
                      <label>Choose dashboard image - 170px x 170px (optional)</label>
                      {renderTileUpload()}
                      {renderTilePreview()}
                    </div>
                  </div>
                  <div className="sg-mgmt-form-row">
                    <div className="sg-mgmt-form-input-container sg-mgmt-form-input-container-color">
                      <label>Choose header color</label>
                      {renderColorPicker()}
                    </div>
                  </div>
                  <div className="sg-mgmt-form-section-divider mb-2 pt-2" />
                  { eventMetadata && eventMetadata.data ? (
                    <div className="sg-mgmt-form-section">
                      <h2 className="mb-2">Custom Fields</h2>
                      {renderMetadataFields()}
                    </div>
                  ):""}
          
                  <div className="sg-mgmt-form-section">
                    <h2 className="mb-2">Growl Web</h2>
                    <div className="sg-mgmt-form-row">{renderTextField("Site URL", "event[site_url]")}</div>
                  </div>
                  {renderGrowlWebUrlField()}
                  {renderGrowlWebKeyField()}
                  <div className="sg-mgmt-form-section-divider mb-2 pt-2" />
                  <div className="sg-mgmt-form-section">
                    <h2 className="mb-2">Postmark Configuration</h2>
                    <div className="sg-mgmt-form-row">
                      {renderTextField("Postmark Mail Key", "event[postmark_mail_key]")}
                    </div>
                  </div>
                </div>
              </div>
              {renderButtons(isSubmitting)}
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  if (eventMetadata.isLoading) {
    return <Loading />;
  }

  return (
    <div className="sg-mgmt-content-frame">
      <PageHeader text={config.title} />
      <div className="mt-2">{renderForm()}</div>
    </div>
  );
};

MainEventForm.defaultProps = {
  event: MainBlankEvent
};

MainEventForm.propTypes = {
  event: PropTypes.object,
  rootUrl: PropTypes.string.isRequired
};

export default MainEventForm;
