import React, { useContext } from "react";

import EventUserContext from "@event/EventUserContext";
import TabbedPage from "@shared/TabbedPage";
import { TabSettingsIcon } from "@shared/tabs/TabIcons";

import TicketingPackages from "./packages/TicketingPackages";
import TicketRequests from "./ticket_requests/TicketRequests";
import TicketingTickets from "./tickets/TicketingTickets";
import TicketingTicketEditSettings from "./tickets/TicketingTicketEditSettings";

const Ticketing = () => {
  const { user } = useContext(EventUserContext);

  const getTabs = () => {
    let tabs = [
      {
        label: "Tickets",
        component: TicketingTickets,
        componentProps: {}
      },
      {
        label: "Packages",
        component: TicketingPackages,
        componentProps: {}
      },
      {
        label: "Ticket Requests",
        component: TicketRequests,
        componentProps: {}
      }
    ];
    if (user && (user.role != "basic" || user.permission.ticketing_edit)) {
      tabs.push({
        label: "Settings",
        component: TicketingTicketEditSettings,
        componentProps: {},
        icon: TabSettingsIcon
      });
    }

    return tabs;
  };
  return <TabbedPage tabs={getTabs()} />;
};

export default Ticketing;
