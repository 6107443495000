import React, { useContext, useEffect, useState } from "react";

import { Stack, Chip } from "@mui/material";
import axios from "axios";
import { Formik, Form } from "formik";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import {
  renderCancelButton,
  renderDateField,
  renderSelectField,
  renderTextField,
  renderTextAreaField,
  renderIntegerField,
  renderButton,
  renderSubmitButton
} from "@shared/FormUtils";
import { useAddTicketType, useUpdateTicketType } from "@shared/hooks/useTickets";
import SelectField from "@shared/forms/SelectField";
import Loading from "@shared/Loading";

const TicketingTicketTypeForm = (props) => {
  const { ticketType, cancel, callbackSuccess, callbackFailure } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const addTicketType = useAddTicketType(apiRoot, event.id);
  const updateTicketType = useUpdateTicketType(apiRoot, event.id);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tags, setTags] = useState([]);

  const isEdit = () => {
    return ticketType;
  };

  useEffect(() => {
    const fetchTicketType = async () => {
      try {
        if (ticketType) {
          const result = await axios(urljoin(apiRoot, `ticketing/ticket_types/${ticketType.id}`));
          // updateTicketType(result.data["ticket_type"]);
          setSelectedTags(result.data["ticket_type"]["tags"].map(tag => {return {name: tag.name, gid: tag.gid}}))
        } else {
          // const result = await axios(urljoin(apiRoot, `ticketing/ticket_types/new`));
          // addTicketType(result.data["ticket_type"]);
        }
      } catch (error) {
        alertHttpError(error);
      }
    };
    const fetchTicketTags = async () => {
      try {
        const result = await axios(urljoin(apiRoot, `ticketing/ticket_tags`));
        setTags(result.data["tags"]);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchTicketType();
    fetchTicketTags();
  }, []);

  const formConfig = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-forms-edit",
        formUrl: urljoin(apiRoot, "/ticketing/ticket_types", `/${ticketType.id}`),
        method: "PATCH",
        saveButton: "Save",
        buttonProperties: { color: "primary" },
        title: "Edit " + ((ticketType || {}).name || "")
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-forms-add",
      formUrl: urljoin(apiRoot, "/ticketing/ticket_types/"),
      method: "POST",
      saveButton: "Create",
      buttonProperties: { color: "secondary" },
      title: "Create New Ticket Type"
    };
  })();

  const ticketTypeInitialValues = () => {
    return {
      archived: ticketType?.archived || false,
      end_datetime: ticketType?.end_datetime || null,
      name: ticketType?.name || "",
      sort_order: ticketType?.sort_order || 0,
      start_datetime: ticketType?.start_datetime || null,
      total_count_type: ticketType?.total_count_type || "unlimited",
      total_count: ticketType?.total_count || 0,
      notes: ticketType?.notes || ""
    };
  };

  const formValidation = (values) => {
    const errors = {};
    if (!values.ticket_type.name) {
      alert("You must enter a unique ticket name.");
      errors.ticket_type = errors.ticket_type || {};
      errors.ticket_type.name = "Required";
    }

    if (
      values.ticket_type.ticket_count_type == "limited" &&
      (isNaN(values.ticket_type.total_count) || values.ticket_type.total_count < 1)
    ) {
      alert("You must enter a total available ticket count.");
      errors.ticket_type = errors.ticket_type || {};
      errors.ticket_type.total_count = "Required";
    }
    console.log({
      errors
    });
    return errors;
  };

  const addTag = (gid) => {
    // ignore duplicate tag
    if (selectedTags.map((t) => t.gid).includes(gid)) {
      return;
    }

    // ignore missing tag
    if (!gid) {
      return;
    }

    const tag = tags.find((t) => t.gid === gid);
    setSelectedTags([tag].concat(selectedTags));
  };

  const removeTag = (gid) => {
    setSelectedTags((prevTags) => prevTags.filter((t) => t.gid !== gid));
  };

  const handleAddTagForm = (value) => {
    addTag(value);
  };

  const renderSelectedTags = () => {
    return (
      <div className="mt-2 mb-2 flex">
        {selectedTags.map((tag) => (
          <Chip key={tag.id} className="mr-2" color="primary" label={tag.name} onDelete={() => removeTag(tag.gid)} />
        ))}
      </div>
    );
  };

  const renderTagAddButton = (values) => {
    const tag_add = values.tag_add;
    const selectedTag = tag_add ? tag_add : false;
    const disabled = !selectedTag || selectedTag === "Select option";

    return renderButton(
      "Add",
      () => {
        handleAddTagForm(tag_add);
      },
      { disabled: disabled, color: "secondary" }
    );
  };

  const renderTagSelect = (values) => {
    const filteredTags = tags.filter((t) => !selectedTags.map((tag) => tag.gid).includes(t.gid));
    const options = filteredTags.map((opt) => ({
      label: opt.name,
      value: opt.gid
    }));
    return (
      <Stack spacing={2} direction="row">
        <SelectField disableLabel={true} name={"tag_add"} options={options} />
        {renderTagAddButton(values)}
      </Stack>
    );
  };

  const renderTags = (values) => {
    return (
      <>
        {renderTagSelect(values)}
        {renderSelectedTags()}
      </>
    );
  };

  const renderFormFields = (formikProps) => {
    return (
      <div className="sg-mgmt-form-container">
        <div className="sg-mgmt-form-section">
          <div className="flex">
            <div className="mr-4 w-full">{renderTextField("Ticket Name", "ticket_type[name]", [], false, true)}</div>
          </div>

          <div className="flex">
            <div className="mr-4 w-full">
              {renderSelectField(
                "Ticket Count",
                "ticket_type[total_count_type]",
                [
                  { value: "unlimited", label: "Unlimited" },
                  { value: "limited", label: "Limited" }
                ],
                false,
                true
              )}
            </div>
          </div>
          {formikProps.values.ticket_type.total_count_type == "limited" ? (
            <div className="flex">
              <div className="mr-4 w-full">
                {renderIntegerField(
                  "Total Available Tickets",
                  "ticket_type[total_count]",
                  [],
                  0,
                  1,
                  10000000,
                  false,
                  true
                )}
              </div>
            </div>
          ) : (
              ""
            )}

          <div className="flex">
            <div className="mr-4 w-1/2">{renderDateField("Start Date (optional)", "ticket_type[start_datetime]")}</div>
            <div className="mr-4 w-1/2">{renderDateField("End Date (optional)", "ticket_type[end_datetime]")}</div>
          </div>

          <div className="flex">
            <div className="mr-4 w-full">{renderTextAreaField("Description", "ticket_type[notes]")}</div>
          </div>

          <h2>Tags</h2>
          {renderTags(formikProps.values)}
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          ticket_type: ticketTypeInitialValues()
        }}
        validate={formValidation}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, { setSubmitting }) => {
          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);
          const token = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
          // Put tags into array
          const tagGids = selectedTags.map((t) => t.gid);
          formData.set("ticket_type[tags]", tagGids);
          
          // Remove tag_add field
          formData.delete("tag_add");

          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: formData
          })
            .then((response) => {
              console.log({
                response
              });
              if (response.data.error == null) {
                callbackSuccess ? callbackSuccess(response) : () => {};
                alertSuccess("Ticket Type Saved");
                callbackSuccess();
              } else {
                alertError(`Unable to save ticket type: ${data.error}`);
                callbackFailure();
                setSubmitting(false);
              }
            });
            // onError: (error) => {
            //   if (error?.response?.data?.error) {
            //     alertError(error.response?.data?.error);
            //   } else {
            //     alertHttpError(error);
            //   }
            //   setSubmitting(false);
            // }
          // };

          if (isEdit()) {
            updateTicketType.mutate({ id: ticketType.id, data: values }, callbacks);
          } else {
            addTicketType.mutate({ data: values }, callbacks);
          }
        }}
      >
        {(formikProps) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            {renderFormFields(formikProps)}
            {renderButtons(formikProps)}
          </Form>
        )}
      </Formik>
    );
  };

  const renderButtons = (formikProps) => {
    const { isSubmitting } = formikProps;
    return (
      <Stack direction="row" spacing={2}>
        {renderSubmitButton(formConfig.saveButton, isSubmitting, formConfig.buttonProperties["color"])}
        {renderCancelButton("Cancel", cancel, formConfig.buttonProperties["color"])}
      </Stack>
    );
  };

  return (
    <div>
      <div className={`sg-mgmt-modal-title ${isEdit() ? "sg-mgmt-modal-title-edit" : ""}`}>{formConfig.title}</div>
      <div className="sg-mgmt-modal-content">
        <div>{renderForm()}</div>
      </div>
    </div>
  );
};

export default TicketingTicketTypeForm;
