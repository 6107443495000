import React from "react";
import PropTypes from "prop-types";
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "@shared/ErrorBoundary";
import MuiAlert from "@shared/MuiAlert";
import Main from "./Main";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

const MainApp = props => {
  const queryClient = new QueryClient();
  return (
    <ErrorBoundary>
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3500}
        Components={{
          mui: MuiAlert
        }}
      >
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Main {...props} />
          </BrowserRouter>
        </QueryClientProvider>
      </SnackbarProvider>
    </ErrorBoundary>
  );
};

MainApp.propTypes = {
  config: PropTypes.object.isRequired,
  rootUrl: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
};

export default MainApp;
